/* ==========================================================================
   Blocks - Tables
   ========================================================================== */

/* Default
   ========================================================================== */
.table-overflow-container {
    max-width: 100%;
    overflow-x: scroll;
}


/**
 * @section Tables
 * @sectionof Blocks
 */


/**
 * @doc docs/table.md
 * @section
 * @sectionof Blocks.Tables
 */

/**
 * @doc docs/table-overflow.md
 * @section
 * @sectionof Blocks.Tables
 */
