/* ==========================================================================
   Colors

   This file is exclusively intended for setting up variables
   Never add styles directly to this file
   ========================================================================== */

/* Colors
   ========================================================================== */
$black:                 #373737;
$white:                 #FFFFFF;

$grey:                  #9D9D9D;
$blue:                  #5CB8E6;

$red:                   #FF4600;


/* Brand colors
  ========================================================================== */
$brand-primary:         $blue;
$brand-primary--dark:   darken($brand-primary, 15%);
$brand-danger:          $red;

$primary-color:         #005862;
$secondary-color:       lighten(#005862, 15%);
$tertiary-color:        #9D9D9D;


/* Specifics
   ========================================================================== */
$text-color:            $black;
$hr-color:              $grey;
$link-color:            $brand-primary;
$link-color--hover:     darken($link-color, 15%);


/**
 * @section Colors
 * @page Index
 */


/**
 * @section Greyscale
 * @sectionof Colors
 *
 * @color {#373737} Black - [Colors.Greyscale]
 * @color {#FFFFFF} White - [Colors.Greyscale]
 */

/**
* @section Brand colors
* @sectionof Colors
*
* @color {#5CB8E6} Brand primary - [Colors.Brand colors]
* @color {#053046} Brand primary dark - [Colors.Brand colors]
* @color {#FF4600} Brand danger - [Colors.Brand colors]
*/
