/* ==========================================================================
   Footer - Main footer
   ========================================================================== */

/* General
   ========================================================================== */
footer {
    padding-top: .5em;
    padding-bottom: .5em;
    .switch_admin {
        position: fixed;
        left: 15px;
        bottom: 15px;
        padding: 30px;
        background: rgba($primary-color, .7);
        a {
            color: $white;
        }
    }
}

