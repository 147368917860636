/* ==========================================================================
   Pageparts - Header pp
   ========================================================================== */


/**
 * @doc docs/header-pp.md
 * @section
 * @sectionof Pageparts
 */
