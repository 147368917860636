/* ==========================================================================
   Pageparts - Totop pp
   ========================================================================== */

.totop-pp {
    padding-bottom: 10px;
    margin: 50px 0;

    border-bottom: 1px solid $hr-color;

    font-size: 15px;
    text-align: right;

    a {
        display: inline-block;

        color: $text-color;
    }
}


/**
* @doc docs/totop-pp.md
* @section
* @sectionof Pageparts
*/
