/* ==========================================================================
   Pageparts - Text pp
   ========================================================================== */

/* General
   ========================================================================== */
.text-pp {
    max-width: 720px;
    margin: $default-margin 0;
}


/**
* @doc docs/text-pp.md
* @section
* @sectionof Pageparts
*/
