/* ==========================================================================
   hr
   ========================================================================== */

hr {
    margin: 32px 0;

    border: 0;
    border-top: 1px solid $grey;
}

aside {
    hr {
        margin: 16px 0;
    }
}


/**
 * @doc docs/hr.md
 * @section
 * @sectionof Blocks
 */
