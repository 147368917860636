/* ==========================================================================
   Fonts

   This file is exclusively intended for setting up variables
   Never add styles directly to this file
   ========================================================================== */

/* Webfonts
   ========================================================================== */
$primary-font: "Helvetica Neue", Helvetica, Arial, sans-serif;
$secondary-font: "Times New Roman", serif;


/* Iconfonts
   ========================================================================== */
$iconfont-name: "icomoon-frontend";


/* Defaults
   ========================================================================== */
$font-family--base:         $primary-font;
$font-family--headings:     $primary-font;
$font-family--buttons:      $primary-font;

/* Weights
   ========================================================================== */

$font-thin:       100;
$font-extra-thin: 200;
$font-light:      300;
$font-normal:     400;
$font-medium:     500;
$font-semi-bold:  600;
$font-bold:       700;
$font-extra-bold: 800;
$font-black:      900;
