/* ==========================================================================
   Pageparts - Introtext pp
   ========================================================================== */

/* General
   ========================================================================== */
.introtext-pp {
    max-width: 720px;
    margin: $default-margin auto;
}


/**
 * @doc docs/introtext-pp.md
 * @section
 * @sectionof Pageparts
 */
