/* ==========================================================================
   Pageparts - Button pp
   ========================================================================== */

/* General
   ========================================================================== */
.btn-pp {
    margin: $default-margin 0;
}


/* Alignment
   ========================================================================== */
.btn-pp--left {
    text-align: left;
}

.btn-pp--center {
    text-align: center;
}

.btn-pp--right {
    text-align: right;
}

.btn-pp--block {
    .btn {
        display: block;
    }
}


/**
 * @doc docs/button-pp.md
 * @section
 * @sectionof Pageparts
 */
