@charset "UTF-8";

/* ==========================================================================
   Extra Stylesheet for admin
   ========================================================================== */

/* Config (necessary only)
   ========================================================================== */
@import "config/general/colors";
@import "config/general/typography/fonts";
@import "config/general/typography/bodycopy";
@import "config/general/sizes";
@import "config/general/paths";

/* Vendors - only vars!
   ========================================================================== */
@import "config/vendors/bootstrap-vars";

/* General
   ========================================================================== */
@import "general/typography/iconfont";

/* Helpers
   ========================================================================== */
@import "helpers/helpers";

@import "admin/footer/footer";


/* Use these styling only in the pagepart previews
   ========================================================================== */
.page-template__region .admin-region,
.pp__view .pp__view__block:first-child {

    /* General
       ========================================================================== */
    @import "general/typography/bodycopy";

    /* Blocks
       ========================================================================== */
    @import "components/blocks/img";
    @import "components/blocks/hr";
    @import "components/blocks/lists";
    @import "components/blocks/links";
    @import "components/blocks/buttons";
    @import "components/blocks/tables";

    /* Structures
       ========================================================================== */

    /* Pageparts
       ========================================================================== */
    @import "components/pageparts/button-pp";
    @import "components/pageparts/download-pp";
    @import "components/pageparts/header-pp";
    @import "components/pageparts/image-pp";
    @import "components/pageparts/introtext-pp";
    @import "components/pageparts/link-pp";
    @import "components/pageparts/text-pp";
    @import "components/pageparts/totop-pp";
    @import "components/pageparts/video-pp";

    /* Forms
        ========================================================================== */

    @import "components/forms/autocomplete";
}

/*
    Fichier style applicable à tout le backend
*/

/* Actions Devis
   ========================================================================== */

.table__actions {
    .btn-group {
        .btn {
            /* réduire taille boutons */
            padding: 5px;
        }

        /* corriger la couleur au passage de la souris écrasée par le template de base */
        .btn-primary:hover {
            background-color: darken(#2997CE, 7%);

            border-color: darken(#2997CE, 12%);
        }
    }


    .btn-group {
        .btn-devis-status {

            &.btn-danger {
                background-color: #DD4B39;;
                border-color: #D73925;;
            }

            &.btn-success {
                background-color: #00A65A;
                border-color: #008D4C;
            }

            &.btn-warning {
                background-color: #F39C12;
                border-color: #E08E0B;
            }

            &.btn-info {
                background-color: #00C0EF;
                border-color: #00ACD6;
            }
        }
    }
}

.loader-container {
    min-height: 35px;
    padding: 5px 0;
    .loader {
        display: none;
        text-align: center;
        font-size: 1.2em;
    }
}
.loading .loader-container .loader {
    display: block;
}

.btn-banette {
    background-color: #9932CC;
    color: white;
    &:hover {
        color: white;
    }
    &:focus {
        color: white;
    }
}

/* Autocomplete
   ========================================================================== */

.autocomplete-suggestions {
    top: 100%;

    height: auto !important;
    overflow: auto;

    background: #FFFFFF;

    border: 1px solid #909090;

    font-size: 14px;
}

.autocomplete-suggestion {
    padding: 2px 5px;
    overflow: hidden;

    white-space: nowrap;
}

.autocomplete-selected { background: #2997CE; }

.autocomplete-suggestions strong {
    font-weight: bold;
    color: #000000;
}

.autocomplete-group { padding: 2px 5px; }

.autocomplete-group strong {
    display: block;

    border-bottom: 1px solid #363B3E;
}

/* Formulaires
   ========================================================================== */

h3 {
    margin: 0 0 20px 0;
}

/* Footer
   ========================================================================== */
.app__footer__kunstmaan-link {
    color: #000000;
}

/* Dashboard
   ========================================================================== */
.dashboard-p {
    @import "config/vendors/adminLTE";
    padding: 0;
    margin: 0 -16px 40px -16px;

    .header {
        padding: 20px;
        margin-top: -15px;

        background-color: $primary-color;

        h2 {
            font-size: 50px;
            line-height: 50px;
            font-weight: $font-bold;
            color: rgba($white, .8);
        }

        .h2-back-wrapper h2 {
            margin: 50px 0 0;
        }

        p {
            margin: 0;
        }
    }

    .small-box {
        border-radius: 0;

        .inner {
            padding: 20px 10px;
            h3 {
                margin: 0;
                color: $white;
            }

            p {
                margin: 0 0 10px 0;
                color: $white;
            }
        }

        i {
            color: $white;
        }

        .icon {
            top: 10px;

            font-size: 70px;
            line-height: 70px;
        }

        a:focus {
            color: $white;
        }
    }

    .small-box:hover {
        .icon {
            font-size: 75px;
        }
    }

    .box {
        border-radius: 0;
        border-top-color: $primary-color;

        color: $black;

        .box-body {
            margin-top: 0;
        }
    }

    .box.box-primary {
        border-top-color: $primary-color;
    }

    .box.box-secondary {
        border-top-color: $secondary-color;
    }

    .background-1-theme {
        background-color: $primary-color;
    }

    .background-2-theme {
        background-color: $secondary-color;
    }

    .background-3-theme {
        background-color: $tertiary-color;
    }


    .ca {
        color: $primary-color;
        font-weight: $font-extra-bold;
        font-size: 28px;
    }
}
