/* ==========================================================================
   Images
   ========================================================================== */

/* Shared
   ========================================================================== */
img {
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

figure {
    margin: 0;

    img {
        display: block;
    }
}


/**
 * @section Images
 * @sectionof Blocks
 */


/**
 * @doc docs/img.md
 * @section
 * @sectionof Blocks.Images
 */

/**
 * @doc docs/img-caption.md
 * @section
 * @sectionof Blocks.Images
 */
