/* ==========================================================================
   Pageparts - Download pp
   ========================================================================== */

/* General
   ========================================================================== */
.download-pp {
    margin: $default-margin 0;
}


/**
 * @doc docs/download-pp.md
 * @section
 * @sectionof Pageparts
 */
