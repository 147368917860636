/* ==========================================================================
   Sizes

   This file is exclusively intended for setting up variables
   Never add styles directly to this file
   ========================================================================== */


/* Margins
   ========================================================================== */
$default-margin: 15px;
