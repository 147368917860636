/* ==========================================================================
   Pageparts - Image pp
   ========================================================================== */

/* General
   ========================================================================== */
.image-pp {
    margin: $default-margin 0;
}


/**
 * @doc docs/image-pp.md
 * @section
 * @sectionof Pageparts
 */
