/* ==========================================================================
   Icon font

   Based on icomoon style.css
   ========================================================================== */

/* General
   ========================================================================== */
@font-face {
    font-family: $iconfont-name;
    src: url("data:application/x-font-ttf;charset=utf-8;base64,%BASE64%") format('truetype');

    font-weight: normal;
    font-style: normal;
}

.icon,
%icon {
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: $iconfont-name;
    speak: none;

    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
}


/**
* @doc docs/iconfont.md
* @section
* @sectionof Typography
*/
