/* ==========================================================================
   Autocomplete
   ========================================================================== */

/* General
   ========================================================================== */

.autocomplete-suggestions {
    top: 100%;

    height: auto !important;
    overflow: auto;

    background: #000000;

    border: 1px solid #909090;

    font-size: 14px;
}

.autocomplete-suggestion {
    padding: 2px 5px;
    overflow: hidden;

    white-space: nowrap;
}

.autocomplete-selected { background: #F0F0F0; }

.autocomplete-suggestions strong {
    font-weight: bold;
    color: #000000;
}

.autocomplete-group { padding: 2px 5px; }

.autocomplete-group strong {
    display: block;

    border-bottom: 1px solid #363B3E;
}
