/* ==========================================================================
   Paths

   This file is exclusively intended for setting up variables
   Never add styles directly to this file
   ========================================================================== */


/* General
   ========================================================================== */
$root:                  "/frontend";


/* Image folders
   ========================================================================== */
$general:               "#{$root}/img/general";

