/* ==========================================================================
   Pageparts - Link pp
   ========================================================================== */

/* General
   ========================================================================== */
.link-pp {
    margin: $default-margin 0;
}


/**
* @doc docs/link-pp.md
* @section
* @sectionof Pageparts
*/
