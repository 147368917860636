/* ==========================================================================
   Pageparts - Video pp
   ========================================================================== */

/* General
   ========================================================================== */
.video-pp {
    margin: 30px 0;

    .video-pp__link__img {
        width: 100%;
    }
}


/* videolink
   ========================================================================== */
.videolink {
    a {
        position: relative;

        display: block;
    }

    .video-link__video-link__icon {
        position: absolute;
        top: 50%;
        left: 50%;

        display: block;
        width: 100px;
        height: 100px;
        margin: -50px 0 0 -50px;

        background: rgba($white, .9);

        border-radius: 50%;

        color: $brand-primary;
        font-size: 66px;
        line-height: 100px;
        text-align: center;

        &:before {
            content: '';

            position: relative;
            top: 3px;
            left: 5px;

            display: inline-block;

            border-style: solid;
            border-width: 25px 0 25px 40px;
            border-color: transparent transparent transparent currentColor;
        }
    }
}

.video-pp__caption {
    margin: 10px 0 0;

    font-size: 14px;
}

.video-pp__full-width-wrapper {
    position: relative;

    padding: 0 15px;

    @media (min-width: $screen-md-min) {
        width: 150%;

        .video-pp__caption {
            width: calc(((100% - 15px) / 3) * 2);
        }

        .video-pp__caption__text {
            max-width: 670px;
            padding: 0 15px;
            margin: 0 auto;
        }

        .video-pp__link__img {
            width: 100%;
        }
    }
}


/**
* @doc docs/video-pp.md
* @section
* @sectionof Pageparts
*/
