/* ==========================================================================
   Bodycopy

   This file is exclusively intended for setting up variables
   Never add styles directly to this file
   ========================================================================== */

/* Line-height
   ========================================================================== */
$line-height--base:     1.6;

$line-height--small:    1.1;
$line-height--medium:   1.2;


/* Font sizes
   ========================================================================== */
$font-size--base:               10px;
$font-size--default:            16px;
$font-size--small:              12px;
$font-size--big:                18px;
